import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Select,
  Modal,
  Steps,
  Popover,
} from "antd";
import { Card, CardBody, CardHeader } from "react-bootstrap";
import axios from "axios";
import ListSJDriver from "./ListSJDriver";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { BaseUrlRace } from "../../../Api/BaseUrl";

const { Title } = Typography;
const { Option } = Select;

const customDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        Step {index} - Status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);

function DetailSJDriver() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  // State to control modal visibility
  const [isModalVisible, setIsModalVisible] = useState(false);
  const vehicleStatus = data?.statusKendaraan || [];

  // // Function to show modal
  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // Function to handle modal OK click
  const handleOk = () => {
    // Add your update logic here
    console.log("Updating SJ...");
    setIsModalVisible(false);
  };

  // Function to handle modal cancel click
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Fetch data from API
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BaseUrlRace}sp/get-sm-detail?msm=11-SJ-24-021002`)
      .then((response) => {
        setData(response.data.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false);
      });
  }, []);

  // Define columns for the Ant Design Table
  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Keterangan",
      dataIndex: "keterangan",
      key: "keterangan",
      width: "10rem",
    },
    {
      title: "Tanggal",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Foto",
      dataIndex: "foto",
      key: "foto",
      render: (foto) => <img src={foto} alt="status" style={{ width: 100 }} />,
    },
    {
      title: "Memo",
      dataIndex: "memo",
      key: "memo",
    },
  ];

  const lat = data?.positionDriverNow?.latitude;
  const lon = data?.positionDriverNow?.longitude;

  // OpenStreetMap URL
  const openStreetMapUrl =
    lat && lon
      ? `https://www.openstreetmap.org/export/embed.html?bbox=${lon}%2C${lat}&layer=mapnik&marker=${lat}%2C${lon}`
      : null;

  const history = useHistory();

  const showModal = () => {
    // Check if the driver name and password are present in localStorage
    const driverName = localStorage.getItem("driverName");
    const driverPassword = localStorage.getItem("driverPassword");

    if (!driverName || !driverPassword) {
      // If not logged in, display SweetAlert2
      Swal.fire({
        title: "Anda perlu login terlebih dahulu",
        text: "Silakan login untuk melanjutkan.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Login",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          // Redirect to the login page
          history.push("/login/admin/driver");
        }
      });
    } else {
      // If logged in, show the modal
      setIsModalVisible(true);
    }
  };

  return (
    <div>
      <div>
        <Card className="p-4">
          <Row gutter={[16, 24]}>
            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <h4>DETAIL SJ</h4>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Col
                xs={24}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={showModal}
                >
                  Update SJ
                </Button>
              </Col>
            </Col>
          </Row>
        </Card>

        <br />

        <Card className="p-4">
          <h6 style={{ fontSize: "20px" }}>Status Kendaraan</h6>
          <div
            style={{
              backgroundColor: "white",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          >
            <Steps
              current={vehicleStatus.length - 1} // Set the current step to the last one
              progressDot={customDot}
              items={vehicleStatus.map((status, index) => ({
                title: status.status,
                description: (
                  <div>
                    <p>{new Date(status.date).toLocaleString()}</p>
                  </div>
                ),
              }))}
            />
          </div>
          <br />
        </Card>
      </div>

      <Modal
        centered
        title="Update SJ"
        width={1500}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ListSJDriver />
      </Modal>
      <br />
      <Row gutter={[16, 24]} justify="center">
        <Col xs={24} md={12}>
          <Card style={{ backgroundColor: "transparent", border: "none" }}>
            <div
              className="bg-gradient-to-b from-blue-500 to-blue-700 p-6 text-center"
              style={{
                background: `linear-gradient(to bottom, #3b82f6, #1e3a8a)`,
                color: "white",
                borderRadius: "8px",
              }}
            >
              <CardHeader>
                <Title level={4} style={{ color: "white" }}>
                  Detail SJ
                </Title>
              </CardHeader>
            </div>
            <CardBody style={{ backgroundColor: "white", height: "auto" }}>
              {data && (
                <Form layout="">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={8}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Nama Driver:
                      </div>
                      <Form.Item>
                        <Input value={data.driver} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Nomor Polisi:
                      </div>
                      <Form.Item>
                        <Input value={data.nopol} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        No Hp:
                      </div>
                      <Form.Item>
                        <Input value={data.telp} readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={8}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        SP :
                      </div>
                      <Form.Item>
                        <Input value={data.sp} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Sekolah Tujuan :
                      </div>
                      <Form.Item>
                        <Input value={data.sekolahTujuan} readOnly />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={8}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Sales Erl:
                      </div>
                      <Form.Item>
                        <Input value={data.salesErl} readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={8}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Jenis Kendaraan:
                      </div>
                      <Form.Item>
                        <Input value={data.jenisKendaraan} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Cabang:
                      </div>
                      <Form.Item>
                        <Input value={data.cabang} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Customer:
                      </div>
                      <Form.Item>
                        <Input value={data.customer} readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={8}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Jenis Barang :
                      </div>
                      <Form.Item>
                        <Input value={data.jenisBarang} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={4}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Qty :
                      </div>
                      <Form.Item>
                        <Input value={data.qty} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={4}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Ikat :
                      </div>
                      <Form.Item>
                        <Input value={data.ikat} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={4}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Berat :
                      </div>
                      <Form.Item>
                        <Input value={data.berat} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={4}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Koli :
                      </div>
                      <Form.Item>
                        <Input value={data.koli} readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={4}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Kota :
                      </div>
                      <Form.Item>
                        <Input value={data.kota} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={4}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Kecamatan :
                      </div>
                      <Form.Item>
                        <Input value={data.kecamatan} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Latitude :
                      </div>
                      <Form.Item>
                        <Input
                          value={data.positionDriverNow.latitude}
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Longitude :
                      </div>
                      <Form.Item>
                        <Input
                          value={data.positionDriverNow.longitude}
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Alamat Lengkap :
                      </div>
                      <Form.Item>
                        <Input.TextArea
                          value={data.positionDriverNow.address}
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={12}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Alamat Muat :
                      </div>
                      <Form.Item>
                        <Input.TextArea
                          value={data.alamatMuat.alamat}
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <div
                        className="mb-2"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Alamat Bongkar :
                      </div>
                      <Form.Item>
                        <Input.TextArea
                          value={data.alamatBongkar.alamat}
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div>
                    {openStreetMapUrl && (
                      <Row gutter={[16, 16]}>
                        <Col xs={24}>
                          <div
                            style={{
                              width: "100%",
                              height: "400px",
                              border: "1px solid #ddd",
                              borderRadius: "8px",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "400px",
                                border: "1px solid #ddd",
                                borderRadius: "8px",
                                overflow: "hidden",
                              }}
                            >
                              {openStreetMapUrl && (
                                <iframe
                                  title="Driver Location Map"
                                  src={openStreetMapUrl}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    border: "none",
                                  }}
                                  allowFullScreen=""
                                ></iframe>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Form>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card style={{ backgroundColor: "transparent", border: "none" }}>
            <div
              className="bg-gradient-to-b from-blue-500 to-blue-700 p-6 text-center"
              style={{
                background: `linear-gradient(to bottom, #3b82f6, #1e3a8a)`,
                color: "white",
                borderRadius: "8px",
              }}
            >
              <CardHeader>
                <Title level={4} style={{ color: "white" }}>
                  Status Kendaraan
                </Title>
              </CardHeader>
            </div>
            <CardBody style={{ backgroundColor: "white" }}>
              <Table
                columns={columns}
                dataSource={data?.statusKendaraan}
                rowKey="date"
                loading={loading}
                pagination={{ pageSize: 5 }}
                scroll={{ x: "max-content" }} // Use 'max-content' for automatic width
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default DetailSJDriver;
