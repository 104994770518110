import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore, { history } from "./appRedux/store";
import "./firebase/firebase";
import App from "./containers/App/index";
// import "bootstrap/dist/css/bootstrap.min.css";
import PrintSP from "./routes/Print/PrintSP";
import PrintMasterMitra from "./routes/Print/PrintMasterMitra/PrintMasterMitra";
import PrintSPNew from "./routes/Print/PrintSPNew/PrintSPNew";
import EmailSM from "./routes/CopyData/Email/sm";
import EmailSP from "./routes/CopyData/Email/sp";
import LoginRaceCustomer from "./routes/Race/Login";
import PrintCustomer from "./routes/Print/PrintDetailCustomer/PrintCustomer";
import SjRace from "./SuratjalanRace/PermasingTombolA4";
import print1Halaman2SJ from "./SuratjalanRace/print1Halaman2SJ";
import Print1HalamanFull1Halaman from "./SuratjalanRace/Print1HalamanFullRamean";
import PrintSPRace1Halaman from "./routes/Race/WebAdmin/splist/PrintSPRace";
import PrintPermasingButtonSP from "./routes/Race/WebAdmin/splist/PrintPermasingButton";
import LoginDriver from "./routes/Driver/LoginDriver";
import ListDriver from "./routes/Driver/List Driver/ListDriver";
import PrintRO from "./SuratjalanRace/PrintRO";
const store = configureStore(/* provide initial state if any */);

const NextApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/printSPKListNih/" component={PrintSP} />
        <Route path="/PrintMasterMitra/" component={PrintMasterMitra} />
        <Route path="/PrintCustomer/" component={PrintCustomer} />
        <Route path="/PrintSJRace/" component={SjRace} />
        <Route path="/PrintSPRace/" component={PrintPermasingButtonSP} />
        <Route path="/Race/print1Halaman2SJ/" component={print1Halaman2SJ} />
        <Route
          path="/Race/PrintSJRaceAll1Halaman/"
          component={Print1HalamanFull1Halaman}
        />
        <Route
          path="/Race/PrintSPRace1Halaman/"
          component={PrintSPRace1Halaman}
        />

        <Route path="/PrintSPNew/" component={PrintSPNew} />
        <Route path="/sm/" component={EmailSM} />
        <Route path="/sp/" component={EmailSP} />
        <Route path="/login/race/customer" component={LoginRaceCustomer} />
        <Route path="/login/admin/driver" component={LoginDriver} />
        <Route path="/admin/driver/sj" component={ListDriver} />
        <Route path="/print-ro" component={PrintRO} />
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default NextApp;
