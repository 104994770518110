import React, { useState } from "react";
import {
  Table,
  Pagination,
  Button,
  Modal,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import { Card, CardBody, CardHeader } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Baseurl from "../../../Api/BaseUrl";
// import "./haha.css";

const { Title } = Typography;
const { Option } = Select;

const ListSJDriver = () => {
  const [form] = Form.useForm(); // Use Ant Design form
  const [formData, setFormData] = useState({
    type: "dev",
    status: "",
    lat: "",
    lon: "",
    nopol: "",
    nama: "",
    nohp: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      type: value,
    }));
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token"); // Retrieve token from storage or context
      const response = await axios.post(
        `${Baseurl}driver/update-status-kpu?id=204.205.00054`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the header
          },
        }
      );

      // Success SweetAlert2
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Status updated successfully!",
        confirmButtonText: "OK",
      }).then(() => {
        // Reload the page after the alert is confirmed
        window.location.reload();
      });

      console.log(response.data);
    } catch (error) {
      // Error SweetAlert2
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Failed to update status.",
        confirmButtonText: "OK",
      });

      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const gradientColors = "from-blue-500 to-blue-700";

  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");

  const handleLatChange = (e) => {
    setLat(e.target.value);
  };

  const handleLonChange = (e) => {
    setLon(e.target.value);
  };

  // OpenStreetMap URL
  const openStreetMapUrl = `https://www.openstreetmap.org/export/embed.html?bbox=${lon}%2C${lat}&layer=mapnik&marker=${lat}%2C${lon}`;

  return (
    <div>
      <Row gutter={[16, 24]} justify="center">
        <Col xs={24} md={12}>
          <Card style={{ backgroundColor: "transparent", border: "none" }}>
            <div
              className="bg-gradient-to-b from-blue-500 to-blue-700 p-6 text-center"
              style={{
                background: `linear-gradient(to bottom, #3b82f6, #1e3a8a)`,
                color: "white",
                borderRadius: "8px",
              }}
            >
              <CardHeader>
                <Title level={4} style={{ color: "white" }}>
                  Update SJ
                </Title>
              </CardHeader>
            </div>
            <CardBody style={{ backgroundColor: "white", height: "auto" }}>
              <Form form={form} onFinish={handleSubmit} layout="">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8}>
                    <div
                      className="mb-2"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Nama Driver:
                    </div>
                    <Form.Item name="nama" rules={[{ required: true }]}>
                      <Input placeholder="Enter nama" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <div
                      className="mb-2"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Nopol :
                    </div>
                    <Form.Item name="nopol" rules={[{ required: true }]}>
                      <Input placeholder="Enter nopol" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <div
                      className="mb-2"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      No HP :
                    </div>
                    <Form.Item name="nohp" rules={[{ required: true }]}>
                      <Input placeholder="Enter no hp" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12}>
                    <div
                      className="mb-2"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Tipe :
                    </div>
                    <Form.Item name="type" initialValue="dev">
                      <Select>
                        <Option value="dev">dev</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div
                      className="mb-2"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Status :
                    </div>
                    <Form.Item name="status" rules={[{ required: true }]}>
                      <Select placeholder="Select status">
                        <Option value="pickup">Pickup</Option>
                        <Option value="dalam perjalanan">
                          Dalam Perjalanan
                        </Option>
                        <Option value="sampai tujuan">Sampai Tujuan</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12}>
                    <div
                      className="mb-2"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Latitude :
                    </div>
                    <Form.Item name="lat">
                      <Input
                        placeholder="Enter latitude"
                        value={lat}
                        onChange={handleLatChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div
                      className="mb-2"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Longitude :
                    </div>
                    <Form.Item name="lon">
                      <Input
                        placeholder="Enter longitude"
                        value={lon}
                        onChange={handleLonChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Update KPU Submit
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card style={{ backgroundColor: "transparent", border: "none" }}>
            <div
              className="bg-gradient-to-b from-blue-500 to-blue-700 p-6 text-center"
              style={{
                background: `linear-gradient(to bottom, #3b82f6, #1e3a8a)`,
                color: "white",
                borderRadius: "8px",
              }}
            >
              <CardHeader>
                <Title level={4} style={{ color: "white" }}>
                  Maps SJ
                </Title>
              </CardHeader>
            </div>
            <CardBody
              style={{
                backgroundColor: "white",
                height: "21rem",
                overflow: "hidden",
                position: "relative",
              }}
            >
              {lat && lon ? (
                <iframe
                  width="100%"
                  height="100%" // Set to 100% to fill CardBody completely
                  frameBorder="0"
                  src={openStreetMapUrl}
                  style={{ border: 0, display: "block" }} // Display as block to prevent extra spacing
                  allowFullScreen
                  title="OpenStreetMap"
                ></iframe>
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    margin: "auto",
                    padding: "1rem",
                  }}
                >
                  Please enter valid latitude and longitude to display the map.
                </p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ListSJDriver;
