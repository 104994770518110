import React, { useState, useEffect } from "react";
import { Layout, Menu, Dropdown, Image } from "antd";
import {
  HomeOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MenuOutlined,
  LoginOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import ListSJDriver from "../ListSJDriver/ListSJDriver";
import LogoEureka from "../../../assets/img/LogoEureka.png";
import { useHistory } from "react-router-dom";
import DetailSJDriver from "../ListSJDriver/DetailSJDriver";
import Swal from "sweetalert2";

const { Header, Content } = Layout;

function ListDriver() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState("1");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Assuming mobile width is less than 768px

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  // Handle menu item click
  const handleMenuClick = ({ key }) => {
    setSelectedKey(key);
  };

  // Initialize history for navigation
  const history = useHistory();

  // Check if driver is logged in
  const isLoggedIn =
    !!localStorage.getItem("driverName") &&
    !!localStorage.getItem("driverPassword");

  // Handle Logout
  // Handle Logout
  const handleLogout = () => {
    // Clear localStorage
    localStorage.removeItem("driverName");
    localStorage.removeItem("driverPassword");

    // Show confirmation and redirect to the SJ page after logout
    Swal.fire({
      title: "Logged out",
      text: "You have been logged out successfully.",
      icon: "success",
      confirmButtonText: "OK",
    }).then(() => {
      // Redirect to /admin/driver/sj using history
      history.push("/admin/driver/sj");
    });
  };

  // Handle Login
  const handleLogin = () => {
    // Redirect to login page using history
    history.push("/login/admin/driver");
  };

  // Menu for dropdown
  const menu = (
    <Menu>
      {isLoggedIn ? (
        <Menu.Item key="logout" onClick={handleLogout}>
          <LogoutOutlined />
          Logout
        </Menu.Item>
      ) : (
        <Menu.Item key="login" onClick={handleLogin}>
          <LoginOutlined />
          Login
        </Menu.Item>
      )}
    </Menu>
  );

  // Function to render content based on selected key
  const renderContent = () => {
    switch (selectedKey) {
      case "1":
        return <DetailSJDriver />;
      case "2":
        return <h2>Welcome to Dashboard!</h2>;

      default:
        return <h2>Welcome to Dashboard!</h2>;
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/* Sidebar */}
      <div
        style={{
          position: isMobile && !isSidebarVisible ? "absolute" : "fixed", // Use absolute for mobile when hidden
          top: 0,
          left: 0,
          width: "200px",
          height: "100%",
          background: "#001529",
          color: "white",
          transition: "transform 0.3s ease", // Use transform for smooth animation
          transform:
            isSidebarVisible || !isMobile
              ? "translateX(0)"
              : "translateX(-100%)",
          zIndex: 1000,
        }}
      >
        <div
          className="logo"
          style={{
            padding: "16px",
            fontSize: "18px",
            textAlign: "center",
          }}
        >
          👋 Halo, Selamat Datang!
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          onClick={handleMenuClick}
        >
          {/* <Menu.Item key="1" icon={<HomeOutlined />}>
            Dashboard
          </Menu.Item> */}
          <Menu.Item key="1" icon={<HomeOutlined />}>
            Detail SJ Driver
          </Menu.Item>
        </Menu>
      </div>

      {/* Main layout */}
      <Layout
        style={{
          marginLeft: isMobile ? 0 : "200px", // No margin on mobile
          transition: "margin-left 0.3s ease",
          padding: isMobile ? "0 0px" : "0", // Add padding on mobile for content
        }}
      >
        {/* Navbar */}
        <Header
          className="header"
          style={{
            padding: 0,
            background: "#fff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ marginLeft: "20px" }}>
            <Image src={LogoEureka} style={{ width: "8rem" }} />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <span style={{ cursor: "pointer", marginRight: "30px" }}>
                {isLoggedIn ? "Logout" : "Login"}
              </span>
            </Dropdown>
            {/* Show burger menu only on mobile */}
            {isMobile && (
              <MenuOutlined
                style={{
                  fontSize: "24px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
                onClick={toggleSidebar}
              />
            )}
          </div>
        </Header>

        {/* Content */}
        <Content
          style={{
            padding: "20px",
            background: "#f0f2f5",
            minHeight: "calc(100vh - 64px)",
            maxHeight: "calc(100vh - 64px)", // Set maxHeight for both mobile and desktop
            overflowY: "auto", // Enable vertical scrolling for both desktop and mobile
          }}
        >
          {renderContent()}
        </Content>
      </Layout>
    </Layout>
  );
}

export default ListDriver;
