import React, { useState, useEffect } from "react";
import { Input, Button, Form, message } from "antd";
import "antd/dist/antd.css";
import BG from "../../assets/bgDriver.jpg";
import { useHistory } from "react-router-dom";

function LoginDriver() {
  const [driverName, setDriverName] = useState(""); // State for driver name
  const [nopol, setNopol] = useState(""); // State for license plate (nopol)
  const [showPassword, setShowPassword] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Assuming mobile width is less than 768px

  const history = useHistory();

  const predefinedPassword = "drivereureka@kpu2024"; // Predefined password

  const onFinish = (values) => {
    const { password } = values;

    if (password === predefinedPassword && driverName && nopol) {
      // Save driver name and password to localStorage
      localStorage.setItem("driverName", driverName);
      localStorage.setItem("driverPassword", password); // Storing the password
      // Navigate to the next page
      history.push("/admin/driver/sj");
    } else if (!driverName || !nopol) {
      // Show error message if fields are empty
      message.error("Please fill in all fields.");
    } else {
      // Show error message if password is incorrect
      message.error("Incorrect password. Please try again.");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        backgroundImage: !isMobile ? `url(${BG})` : "none", // Hide background on mobile
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundSize: "cover",
        position: "relative", // Make parent div relative for absolute positioning of child
      }}
    >
      {isMobile && (
        <img
          src={BG} // Use the same image or a different one for mobile
          alt="Background"
          style={{
            width: "100vw", // Full width of the viewport
            height: "auto", // Maintain aspect ratio
            position: "absolute", // Position it absolutely
            top: 0, // Align to the top
            left: 0, // Align to the left
            zIndex: 0, // Place it behind other content
          }}
        />
      )}
      <div
        style={{
          padding: "40px",
          backgroundColor: "white",
          borderRadius: isMobile ? "50px 50px 8px 8px" : "16px 16px 8px 8px", // Rounded top corners
          boxShadow: isMobile ? "none" : "0 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "450px",
          width: "100%", // Adjust width to fit nicely on mobile
          height: isMobile ? "auto" : "auto", // Full height on mobile
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // Center form vertically
          alignItems: "center", // Center form horizontally
          position: "relative", // Make this div relative to overlap with the image
          marginTop: isMobile ? "-40px" : "0", // Adjust margin to overlap the image
        }}
      >
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Login</h2>
        <Form
          name="loginForm"
          onFinish={onFinish}
          layout="vertical"
          style={{ width: "100%" }}
        >
          <Form.Item
            label="Driver Name"
            name="driverName"
            rules={[
              { required: true, message: "Please enter your driver name" },
            ]}
          >
            <Input
              placeholder="Enter Driver Name"
              value={driverName}
              onChange={(e) => setDriverName(e.target.value)} // Set driver name in state
            />
          </Form.Item>

          <Form.Item
            label="License Plate (Nopol)"
            name="nopol"
            rules={[
              { required: true, message: "Please enter your license plate" },
            ]}
          >
            <Input
              placeholder="Enter License Plate"
              value={nopol}
              onChange={(e) => setNopol(e.target.value)} // Set nopol in state
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please enter your password" }]}
          >
            <Input.Password
              placeholder="Enter Password"
              type={showPassword ? "text" : "password"}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default LoginDriver;
