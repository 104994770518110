import React from "react";
import { Card } from "react-bootstrap";
import HeaderMasterMitra from "./HeaderMasterMitra";

function PrintMasterMitra() {
  return (
    <div>
      <HeaderMasterMitra />
    </div>
  );
}

export default PrintMasterMitra;
